.xilitla-tours {
  .header-xilitla {
    height: 400px;
    background-image: url("../../../../public/assets/images/header/xilitla.jpeg");
  }
  .header-valles {
    height: 400px;
    background-image: url("../../../../public/assets/images/header/valles.jpeg");
  }
  .cards {
    .zacahuil-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/zacahuil/zacahuil.jpeg");
      height: 550px;
    }

    .bocoles-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/bocoles/bocoles.jpeg");
      height: 550px;
    }

    .enchiladas-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/enchiladas/enchiladas.jpeg");
      height: 550px;
    }

    .acamayas-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/acamayas/acamayas.jpeg");
      height: 550px;
    }

    .mojarras-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/mojarras/mojarras.jpeg");
      height: 550px;
    }

    .marranada-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/marranada/marranada.jpg");
      height: 550px;
    }

    .arqueologico-card {
      width: 400px;
      background-image: url("../../../../public/assets/images/tours/arqueologico/arqueologico.jpg");
      height: 550px;
    }


    @media only screen and (max-width: 768px) {
      .zacahuil-card,
      .bocoles-card,
      .enchiladas-card,
      .acamayas-card,
      .mojarras-card,
      .marranada-card,
      .arqueologico-card{
        width: 300px;
      }
    }
  }
}
