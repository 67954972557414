.root {
  flex: 1;
  .video {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    pointer-events: none;
    iframe {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .video {
      height: 700px;
      padding: 0px;
      iframe {
        width: 100%;
        height: 700px;
      }
    }
  }
  .section1 {
    padding-bottom: 40px;
    width: 100%;
    background-color: var(--green-color);
    .title {
      padding-top: 20px;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 40px;
        color: white;
        text-align: center;
      }
    }

    div {
      margin-right: 30px;
      margin-left: 30px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      section {
        display: flex;
        align-items: center;
        flex-direction: column;
        .icon {
          color: white;
          font-size: 45px;
        }
        h2 {
          color: white;
          font-size: 25px;
          text-align: center;
        }
        p {
          font-size: 20px;
          text-align: center;
          color: white;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      div {
        justify-content: space-around;
        display: flex;
        flex-direction: column;
        section {
          h2 {
            text-align: center;
            color: white;
            font-size: 20px;
          }
        }
      }
    }
  }
  .section2 {
    .title {
      h1 {
        color: var(--green-color);
        font-size: 40px;
      }
    }
    .places {
      .card1 {
        width: 300px;
        height: 550px;
        background-image: url("../../../public/assets/images/homepage/xilitla.svg");
      }
      .card2 {
        width: 300px;
        height: 550px;
        background-image: url("../../../public/assets/images/homepage/tamasopo.jpeg");
        background-position-x: center;
      }
      .card3 {
        width: 300px;
        height: 550px;
        background-image: url("../../../public/assets/images/homepage/sotano.jpeg");
      }
      .card4 {
        width: 300px;
        height: 550px;
        background-image: url("../../../public/assets/images/homepage/tamul.jpeg");
      }
    }
  }
  .section3 {
    height: 1000px;
    background-image: url("../../../public/assets/images/homepage/rzr.jpeg");
    .text {
      h1 {
        font-size: 40px;
      }
    }
  }
}
