.tours4x4 {
  .header-tour4x4 {
    height: 400px;
    background-image: url("../../../public/assets/images/header/tours4x4.jpeg");
  }

  .cards-tours {
    div {
      .trinidad-card {
        width: 460px;
        height: 650px;
        background-image: url("../../../public/assets/images/tours4x4/trinidad-1.jpg");
        background-size: cover;
      }
      .surrealista-card {
        width: 460px;
        height: 650px;
        background-image: url("../../../public/assets/images/tours4x4/surrealista-1.jpg");
        background-size: cover;
      }
      .nacimiento-card {
        width: 460px;
        height: 650px;
        background-image: url("../../../public/assets/images/tours4x4/nacimiento-1.jpg");
        background-size: cover;
      }
      .miradores-card {
        width: 460px;
        height: 650px;
        background-image: url("../../../public/assets/images/tours4x4/mirador-1.jpg");
        background-size: cover;
      }
      .nanacatli-card {
        width: 460px;
        height: 650px;
        background-image: url("../../../public/assets/images/tours4x4/nanacatli-1.jpg");
        background-size: cover;
      }
      @media only screen and (max-width: 768px) {
        .trinidad-card,
        .surrealista-card,
        .nacimiento-card,
        .miradores-card,
        .nanacatli-card {
          width: 300px;
        }
      }
    }
  }
}
