.float {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 25px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 40px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
}
.float:hover {
  text-decoration: none;
  color: #25d366;
  background-color: #fff;
}

